import React, { useState, useEffect } from 'react';
import './App.css';

import { fetchGoogleSheetsData } from 'google-sheets-mapper';
import axiosInstance from './axios';
import { LeaderboardItem, Spreadsheet, SpreadsheetData, LeaderboardPerson, LeaderboardDivider } from './types';
import { Tooltip } from '@mui/material';

const thresholdColor = '#c70d3a'

const getData = async (): Promise<SpreadsheetData> => {
  try {
    const data = await fetchGoogleSheetsData({
      apiKey: "AIzaSyAcmD4PVHSjKAvEM3XZaI0pK1gzGbhuNjk",
      sheetId: "1eh1XBJWSg_Bv7GuYKS5rKJ87eERAe0gk-DC5jONtGDA",
      sheetsOptions: [{ id: 'Leaderboard' }, { id: 'Dividers' }],
    });
    const ldbRaw: Spreadsheet = data[0].data as Spreadsheet;
    const dividersRaw: Spreadsheet = data[1].data as Spreadsheet;
    const sheet: SpreadsheetData= {
      threshold: Number((ldbRaw[0]).Threshold) as number ?? 0,
      people: ldbRaw.map(c => ({
        rank: c.Rank as number,
        name: c.Student as string,
        points: Number(c.Points) as number
      })),
      dividers: dividersRaw.map(c => ({
        color: c.Color as string,
        points: Number(c.Points) as number,
        hoverText: c.Purpose as string
      })).sort((a,b) => a.points-b.points)
    };
    return sheet;
  } catch (error) {
    console.error(error);
    return {threshold: -1, people: [], dividers: []}
  }
};

const LeaderboardItemComponent = ({item}: {item: LeaderboardItem}) => {
  if ((item as LeaderboardPerson).rank) {
    return <LeaderboardPersonComponent person={item as LeaderboardPerson}/>
  }
  else {
    return <LeaderboardDividerComponent divider={item as LeaderboardDivider}/>
  }
}

const LeaderboardPersonComponent = ({person}: {person: LeaderboardPerson}) => {
  return (
    <div className="leaderboard-item">
        <p className="leaderboard-place">{person.rank}</p>
        <p className="leaderboard-name">{person.name}</p>
        <p className="leaderboard-points">{person.points} points</p>
    </div>
  );
}

const LeaderboardDividerComponent = ({divider}: {divider: LeaderboardDivider}) => {
  let text = divider.hoverText ?? "";
  if (text.length > 0 && !text.endsWith(": ")) {
    text += ": "
  }
  text += divider.points + " points";
  return (
    <Tooltip title={text}>
      <div style={{backgroundColor: divider.color}} className="divider"></div>
    </Tooltip>
    
  );
}

function App() {
  const [leaderboard, setLeaderboard] = useState<LeaderboardItem[]>([]);
  const [ldbData, setLdbData] = useState<SpreadsheetData>({threshold: -1, people: [], dividers: []});
  const [refreshTime, setRefreshTime] = useState<string>("");
  const [epa, setEpa] = useState<number>(-1);
  const [worldRank, setWorldRank] = useState<number>(-1);
  const [stateRank, setStateRank] = useState<number>(-1);

  const refreshLeaderboardData = async (): Promise<void> => {
    const data = await getData();
    setLdbData(data);
    if(data.people.length > 0){
      const ldb = addDividersToLeaderboard(data);
      setLeaderboard(ldb);
    }
    setRefreshTime(Date().toLocaleString().slice(0, 24));
  }

  const addDividersToLeaderboard = (ldb: SpreadsheetData): LeaderboardItem[] => {
    let newldb = [];
    let ldbPeople = ldb.people;
    ldbPeople.reverse();
    let peoplei = 0, divideri = 0;
    while(peoplei < ldb.people.length && divideri < ldb.dividers.length) {
      if(ldb.people[peoplei].points < ldb.dividers[divideri].points) {
        newldb.push(ldb.people[peoplei]);
        peoplei++;
      }
      else {
        newldb.push(ldb.dividers[divideri]);
        divideri++;
      }
    }
    // dividers too large purposefully excluded (they're a suprise...)
    while (peoplei < ldb.people.length) {
      newldb.push(ldb.people[peoplei]);
      peoplei++;
    }

    // now for the threshold
    let lowestAboveThreshold = newldb.findIndex(item => item.points >= ldb.threshold);
    lowestAboveThreshold = lowestAboveThreshold == -1 ? newldb.length : lowestAboveThreshold;
    newldb.splice(lowestAboveThreshold, 0, {points: ldb.threshold, color: thresholdColor, hoverText: "Threshold: "});

    return newldb.reverse();
  }

  const getStatboticsData = (): void => {
    try {
      axiosInstance.get("/team_year/4944/2024")
      .then(response => {
        var details = response.data.epa;
        setEpa(details.breakdown.total_points.mean);
        setWorldRank(details.ranks.total.rank);
        setStateRank(details.ranks.state.rank);
      })
    }
    catch (error) {
      console.error(error);
    }    
  }

  useEffect(()=> {
    refreshLeaderboardData();
    getStatboticsData();
    const interval = setInterval(refreshLeaderboardData, 60000);
    return () => {
      clearInterval(interval);
    }
  }, []);

 return (
    <div className="App">
      <header><h1>Hi Fives Leaderboard</h1></header>
      {leaderboard.length !== 0 && <div className="leaderboard">
        {
          leaderboard.map((item, index) => {
            return(
              <div key={index} className="leaderboard-item-container">
                <LeaderboardItemComponent item={item}/>
              </div>
            )
          })
        }
      </div>}
      <h1>Statbotics Rankings:</h1>
      <div className="epa-item-container">
        <div className="epa-item">
          <p className="epa-item-title">EPA</p>
          <p className="epa-item-data">{epa == -1 ? "loading":epa}</p>
        </div>
        <div className="epa-item">
          <p className="epa-item-title">World Ranking</p>
          <p className="epa-item-data">{worldRank == -1 ? "loading":worldRank}</p>
        </div>
        <div className="epa-item">
          <p className="epa-item-title">State Ranking</p>
          <p className="epa-item-data">{stateRank == -1 ? "loading":stateRank}</p>
        </div>
      </div>
      {/* <div style={{padding: 20}}>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/qQzdAsjWGPg?si=us_R1cMHKiDBr1Cj" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </div> */}
      <div>Threshold: {ldbData.threshold} points | Last refreshed: {refreshTime}</div>
    </div>
  );
}

export default App;
